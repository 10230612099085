import React from 'react';
import styled from 'styled-components';
import Sidebar from '../Sidebar/Sidebar';

const Container = styled.div`
  display: flex;
`;

const MainContent = styled.div`
  width: 80%;
  margin-left: 20%;
  padding: 20px;
  box-sizing: border-box;
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 2fr;
  gap: 10px;
  align-items: center;
  text-align: left;
  margin-bottom: 10px;
`;

const DateTimeContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  margin-left: 10px;
`;

class CrearSolicitud extends React.Component {
  state = {
    fecha: '',
    hora: '',
    id_nave: '',
    id_servicio: '',
    trg: '',
    id_pais: '',
    proximoPuerto: '',
    etaFecha: '',
    etaHora: '',
    agencia: '',
    id_practico_1: '',
    id_practico_2: '',
    id_lugar: '',
    id_condicion_climatica: '',
    formAttempted: false,
    showError: false,
    paises: [],
    agencias: [],
    naves: [],
    lugares: [],
    condicionesClimaticas: [],
    practicos: [],
    navesAgencia: [],
    mostrarNavesAgencia: false,
    mostrarNuevaNave: false,
    mostrarNuevoPractico1: false,
    mostrarNuevoPractico2: false,
    nuevaNaveAgencia: '',
    nuevoPractico1: '',
    nuevoPractico2: ''
  }

  componentDidMount() {
    this.fetchPaises();
    this.fetchAgencias();
    this.fetchNaves();
    this.fetchLugares();
    this.fetchCondicionesClimaticas();
    this.fetchPracticos();
  }

  fetchAgencias = async () => {
    const response = await fetch('https://api-practicos.dev.tabsa.cl/api/agencias');
    const data = await response.json();
    this.setState({ agencias: data });
  }

  fetchPaises = async () => {
    const response = await fetch('https://api-tool.dev.tabsa.cl/api/lista/paises');
    const data = await response.json();
    this.setState({ paises: data });
  }

  fetchNaves = async () => {
    const response = await fetch('https://api-practicos.dev.tabsa.cl/api/naves');
    const data = await response.json();
    this.setState({ naves: data });
  }

  fetchLugares = async () => {
    const response = await fetch('https://api-practicos.dev.tabsa.cl/api/lugares');
    const data = await response.json();
    this.setState({ lugares: data });
  }

  fetchCondicionesClimaticas = async () => {
    const response = await fetch('https://api-practicos.dev.tabsa.cl/api/condiciones-climaticas');
    const data = await response.json();
    this.setState({ condicionesClimaticas: data });
  }

  fetchPracticos = async () => {
    const response = await fetch('https://api-practicos.dev.tabsa.cl/api/practicos');
    const data = await response.json();
    this.setState({ practicos: data });
  }

  handleInputChange = async (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  
    if (name === 'id_agencia') {
      const response = await fetch(`https://api-practicos.dev.tabsa.cl/api/naves-agencia/filtro-agencia/${value}`);
      const data = await response.json();
      this.setState({ navesAgencia: data, mostrarNavesAgencia: true });
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!this.isFormValid()) {
      this.setState({ showError: true });
      return;
    }
  
    const eta = `${this.state.etaFecha} ${this.state.etaHora}`;
  
    const solicitudData = {
      fecha: this.state.fecha,
      hora: this.state.hora,
      id_nave: this.state.id_nave,
      id_servicio: this.state.id_servicio,
      trg: this.state.trg,
      id_pais: this.state.id_pais,
      proximoPuerto: this.state.proximoPuerto,
      etaFecha: this.state.etaFecha,
      etaHora: this.state.etaHora,
      id_agencia: this.state.id_agencia,
      id_nave_agencia: this.state.id_nave_agencia,
      nueva_nave_agencia: this.state.nuevaNaveAgencia,
      id_practico_1: this.state.id_practico_1,
      nuevo_practico_1: this.state.nuevoPractico1,
      id_practico_2: this.state.id_practico_2,
      nuevo_practico_2: this.state.nuevoPractico2,
      id_lugar: this.state.id_lugar,
      id_condicion_climatica: this.state.id_condicion_climatica,
      eta: eta
    };
  
    const response = await fetch('https://api-practicos.dev.tabsa.cl/api/crear-solicitud', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(solicitudData)
    });
  
    const data = await response.json();
  
    if (data['solicitud-creada'] === true) {
      alert('Solicitud creada correctamente');
    } else {
      alert('No se ha podido crear la solicitud');
    }
    this.setState({ showError: false });
  }

  isFormValid = () => {
    const { fecha, hora, id_servicio, trg, id_pais, id_agencia } = this.state;
    return fecha && hora && id_servicio && trg && id_pais && id_agencia;
  }  

  toggleNuevaNave = () => {
    this.setState(prevState => ({
      mostrarNuevaNave: !prevState.mostrarNuevaNave,
      id_nave: '',
    }));
  }

  toggleNuevoPractico1 = () => {
    this.setState(prevState => ({
      mostrarNuevoPractico1: !prevState.mostrarNuevoPractico1,
      id_practico_1: '',
    }));
  }

  toggleNuevoPractico2 = () => {
    this.setState(prevState => ({
      mostrarNuevoPractico2: !prevState.mostrarNuevoPractico2,
      id_practico_2: '',
    }));
  }

  render() {
    const { showError, paises, agencias, naves, lugares, condicionesClimaticas, practicos, navesAgencia, mostrarNavesAgencia, mostrarNuevaNave, mostrarNuevoPractico1, mostrarNuevoPractico2, nuevaNaveAgencia, nuevoPractico1, nuevoPractico2 } = this.state;
    const isFormValid = this.isFormValid();
  
    return (
      <>
      <br/>
      <br/>
      <br/>
      <center>
        <Container>
          <Sidebar/>
          <MainContent>
              <div style={{marginTop: '-0px'}} className='container'>
                <h2 style={{color: 'black'}}>Crear Solicitud</h2>
                {showError && <p style={{color: 'red'}}>Rellenar campos obligatorios</p>}
                <form onSubmit={this.handleSubmit}>
                  <FormRow>
                    <label>Fecha y Hora*:</label>
                    <DateTimeContainer>
                      <input style={{width: "46%"}} type="date" name="fecha" onChange={this.handleInputChange} />
                      <input style={{width: "45%"}} type="time" name="hora" onChange={this.handleInputChange} />
                    </DateTimeContainer>
                  </FormRow>
                  <FormRow>
                    <label>Nave*:</label>
                      <select style={{width: "300px"}} name="id_nave" onChange={this.handleInputChange} disabled={mostrarNuevaNave}>
                        <option hidden selected value="">Seleccionar</option>
                        {naves.map(nave => (
                          <option key={nave.id_nave} value={nave.id_nave}>{nave.nombre_nave}</option>
                        ))}
                      </select>
                  </FormRow>
                  <FormRow>
                    <label>Tipo de Maniobra*:</label>
                    <select style={{width: "300px"}} name="id_servicio" onChange={this.handleInputChange}>
                      <option hidden selected value="">Seleccionar</option>
                      <option value="1">Embarque</option>
                      <option value="2">Desembarque</option>
                    </select>
                  </FormRow>
                  <FormRow>
                    <label>TRG*:</label>
                    <input type="text" name="trg" onChange={this.handleInputChange} />
                  </FormRow>
                  <FormRow>
                    <label>Bandera*:</label>
                    <select style={{width: "300px"}} name="id_pais" onChange={this.handleInputChange}>
                      <option hidden selected value="">Seleccionar</option>
                      {paises.map(pais => (
                        <option key={pais.codigo} value={pais.codigo}>{pais.nombre}</option>
                      ))}
                    </select>
                  </FormRow>
                  <FormRow>
                    <label>ETA:</label>
                    <DateTimeContainer>
                      <input style={{width: "46%"}} type="date" name="etaFecha" onChange={this.handleInputChange} />
                      <input style={{width: "45%"}} type="time" name="etaHora" onChange={this.handleInputChange} />
                    </DateTimeContainer>
                  </FormRow>
                  <FormRow>
                    <label>Agencia*:</label>
                    <select style={{width: "300px"}} name="id_agencia" onChange={this.handleInputChange}>
                      <option hidden selected value="">Seleccionar</option>
                      {agencias.map(agencia => (
                        <option key={agencia.id_agencia} value={agencia.id_agencia}>
                          {agencia.nombre}
                        </option>
                      ))}
                    </select>
                  </FormRow>
                  {mostrarNavesAgencia && (
                    <FormRow>
                      <label>Nave de Agencia:</label>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <select style={{width: "300px"}} name="id_nave_agencia" onChange={this.handleInputChange}>
                          <option hidden selected value="">Seleccionar</option>
                          {navesAgencia.map(nave => (
                            <option key={nave.id_nave_agencia} value={nave.id_nave_agencia}>
                              {nave.nombre_nave}
                            </option>
                          ))}
                        </select>
                        <IconButton type="button" onClick={this.toggleNuevaNave}>{!mostrarNuevaNave ? "+" : "-"}</IconButton>
                      </div>
                    </FormRow>
                  )}
                  {mostrarNuevaNave && (
                    <FormRow>
                      <label>Nueva Nave Agencia:</label>
                      <input type="text" name="nuevaNaveAgencia" value={nuevaNaveAgencia} onChange={this.handleInputChange} />
                    </FormRow>
                  )}
                  <FormRow>
                    <label>Práctico 1:</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <select style={{width: "300px"}} name="id_practico_1" onChange={this.handleInputChange} disabled={mostrarNuevoPractico1}>
                        <option hidden selected value="">Seleccionar</option>
                        {practicos.map(practico => (
                          <option key={practico.id_practico} value={practico.id_practico}>
                            {practico.nombre} {practico.apellido}
                          </option>
                        ))}
                      </select>
                      <IconButton type="button" onClick={this.toggleNuevoPractico1}> {!mostrarNuevoPractico1 ? "+" : "-"} </IconButton>
                    </div>
                  </FormRow>
                  {mostrarNuevoPractico1 && (
                    <FormRow>
                      <label>Nuevo Práctico:</label>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input type="text" name="nuevoPractico1" value={nuevoPractico1} onChange={this.handleInputChange} />
                      </div>
                    </FormRow>
                  )}
                  <FormRow>
                    <label>Práctico 2 (Opcional):</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <select style={{width: "300px"}} name="id_practico_2" onChange={this.handleInputChange} disabled={mostrarNuevoPractico2}>
                        <option hidden selected value="">Seleccionar</option>
                        {practicos.map(practico => (
                          <option key={practico.id_practico} value={practico.id_practico}>
                            {practico.nombre} {practico.apellido}
                          </option>
                        ))}
                      </select>
                      <IconButton type="button" onClick={this.toggleNuevoPractico2}> {!mostrarNuevoPractico2 ? "+" : "-"} </IconButton>
                    </div>
                  </FormRow>
                  {mostrarNuevoPractico2 && (
                    <FormRow>
                      <label>Nuevo Práctico:</label>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input type="text" name="nuevoPractico2" value={nuevoPractico2} onChange={this.handleInputChange} />
                      </div>
                    </FormRow>
                  )}
                  <input className='btn2' type="submit" value="Enviar" disabled={!isFormValid} style={{backgroundColor: isFormValid ? '' : 'gray'}} />
                </form>
              </div>
            </MainContent>
          </Container>
        </center>
      </>
    );
  }
}

export default CrearSolicitud;